<template>
    <div class="flex_layout inner">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-input v-model:value="query.product_name" placeholder="Product name" />
                    </div>
                    <div class="query_box">
                        <a-select
                            style="width: 160px"
                            ref="select"
                            v-model:value="query.brand_id"
                            :options="allBrandList"
                            placeholder="Select a brand"
                        >
                        </a-select>
                    </div>
                    <div class="query_box">
                        <a-space>
                            <a-button @click="getProductListHandler">
                              <template #icon><component :is="$antIcons['SearchOutlined']" /></template>
                            </a-button>
                            <a-button class="primary btn" @click="update('add')">Add</a-button>
                            <a-upload
                                :customRequest="(file) => customRequestExcel(file.file)" 
                                :showUploadList="false"
                            >
                                <a-button>
                                    <template #icon><component :is="$antIcons['uploadOutlined']" /></template>
                                    Import
                                </a-button>
                            </a-upload>
                            <a-button @click="getTemplateHandler" type="link">Download the Excel template file</a-button>
                        </a-space>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
                <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                    <template #image="{record}">
                        <img :src="record.image" class="product_image">
                    </template>
                    <template #action="{record}">
                        <a-space>
                            <a-button class="primary btn" @click="update('edit', record)" size="small" type="primary">Edit</a-button>
                            <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.product_id)"
                            >
                                <a-button danger size="small">Delete</a-button>
                            </a-popconfirm>
                        </a-space>
                    </template>
                </a-table>
            </a-spin>
        </div>
        <div class="flex_bottom">
            <a-pagination size="small" @change="changePagination" v-model:current="query.page" :total="total" show-less-items />
        </div>
        <a-drawer
            title="Prdocut class"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
        >
            <div class="container">
                <a-spin :spinning="spinning">
                    <div class="form" style="width: 100%">
                        <div class="form_title">Product</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Product name">
                                <a-input v-model:value="formData.product_name" placeholder="Product name" />
                            </a-form-item>    
                            <a-form-item v-if="show" label="Product description">
                                <Editor @onContent="onContent" :content="formData.product_description" />
                            </a-form-item>    
                            <a-form-item label="Product image">
                                <img :src="product_image" class="product_image">
                                <a-upload :customRequest="(file) => customRequest(file.file, 'product_image')" :showUploadList="false">
                                    <a-button>
                                        <component :is="$antIcons['UploadOutlined']" />
                                        Upload
                                    </a-button>
                                </a-upload>
                            </a-form-item>  
                            <a-form-item label="Product price">
                                <a-inputNumber style="width:50%" v-model:value="formData.product_price" placeholder="Product price" />
                            </a-form-item>    
                            <a-form-item label="Product class">
                                 <a-select
                                    ref="select"
                                    v-model:value="formData.class_id"
                                    :options="productClassList"
                                    @change="changeProductClass"
                                    placeholder="Select product class"
                                >
                                </a-select>
                            </a-form-item> 
                            <a-form-item label="Brand">
                                 <a-select
                                    ref="select"
                                    v-model:value="formData.brand_id"
                                    :options="brandList"
                                    placeholder="Select Brand"
                                >
                                </a-select>
                            </a-form-item>    
                            <a-form-item label="Datasheet">
                                <component :is="$antIcons['FileFilled']" v-if="formData.datasheet && formData.datasheet.length > 0" class="file_icon" />
                                <a-upload :customRequest="(file) => customRequest(file.file, 'datasheet')" :showUploadList="false">
                                    <a-button>
                                        <component :is="$antIcons['UploadOutlined']" />
                                        Upload
                                    </a-button>
                                </a-upload>
                            </a-form-item>  
                            <a-form-item label="Installation manual">
                                <component :is="$antIcons['FileFilled']" v-if="formData.installation_manual && formData.installation_manual.length > 0" class="file_icon" />
                                <a-upload :customRequest="(file) => customRequest(file.file, 'installation_manual')" :showUploadList="false">
                                    <a-button>
                                        <component :is="$antIcons['UploadOutlined']" />
                                        Upload
                                    </a-button>
                                </a-upload>
                            </a-form-item>
                            <a-form-item label="Warranty document">
                                <component :is="$antIcons['FileFilled']" v-if="formData.warranty_document && formData.warranty_document.length > 0" class="file_icon" />
                                <a-upload :customRequest="(file) => customRequest(file.file, 'warranty_document')" :showUploadList="false">
                                    <a-button>
                                        <component :is="$antIcons['UploadOutlined']" />
                                        Upload
                                    </a-button>
                                </a-upload>
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 20, offset: 4 }">
                                <a-button type="primary" @click="updateProductHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-spin>
            </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Product name',
        dataIndex: 'product_name',
        key: 'product_name',
    },
    {
        title: 'Product image',
        slots: { customRender: 'image' },
    },
    {
        title: 'Class name',
        dataIndex: 'class_name',
        key: 'class_name',
    },
    {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand_name',
    },
    {
        title: 'Product price',
        dataIndex: 'product_price',
        key: 'product_price',
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import XLSX from 'xlsx'
import { message } from 'ant-design-vue'
import tabMixins from '@/mixins/tabMixins'
import commonMixins from '@/mixins/commonMixins'
import Editor from '@/components/editor/editor'
import { getBrandList } from '@/apis/brand'
import { getProductClassList } from '@/apis/productClass'
import { getProductList, upload, updateProduct, removeProduct } from '@/apis/product'
import { getTemplate } from '@/apis/home/system'
export default {
    name: 'Product',
    mixins: [tabMixins, commonMixins],
    components: {
        Editor
    },
    data(){
        return {
            dataSource: [],
            columns,
            query: {
                product_name: '',
                brand_id: undefined,
                page: 1,
                page_size: 10
            },
            formData: {},
            brandList: [],
            productClassList: [],
            total: 1,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            visible: false,
            spinning: false,
            product_image: '',
            show: false,
            spinning: false,
            allBrandList: [],
        }
    },
    methods: {
        /**
         * 新增更新产品弹窗
         */
        update(actionType, productInfo){
            if(actionType == 'add'){
                this.initParams()
                this.product_image = ''
            }
            if(actionType == 'edit'){
                this.product_image = productInfo.image
                this.formData = productInfo
            }
            this.getProductClassListHandler()
            this.formData.action_type = actionType
            this.visible = true
            this.show = true
        },
        /**
         * 新增更新产品
         */
        async updateProductHandler(){
            if(this.formData.product_name == '') return message.error('Please input product name')
            this.spinning = true
            const res = await updateProduct(this.formData)
            this.spinning = false
            if(res){
                message.success('success')
                this.visible = false
                this.getProductListHandler()
            } 
        },
        /**
         * 关闭弹窗
         */
        onClose(){
            this.visible = false
            this.show = false
        },
        /**
         * 产品图片上传
         */
        async customRequest(file, key){
            this.spinning = true
            const res = await upload(file)
            this.spinning = false
            if(res){
                if(key == 'product_image'){
                    this.product_image = res.file_name
                }
                this.formData[key] = res.name
            }
        },
        /**
         * 编辑器回调
         */
        onContent(html){
            this.formData.product_description = html
        },
        /**
         * 删除产品
         */
        async remove(product_id){
            this.spinning = true
            const res = await removeProduct({product_id})
            this.spinning = false
            if(res){
                this.getProductListHandler()
            } 
        },
        /**
         * 获取产品列表
         */
        async getProductListHandler(){
            this.dataSource = []
            this.spinning = true
            const res = await getProductList(this.query)
            this.spinning = false
            this.dataSource = res.list.rows
            this.total = res.list.count
        },
        /**
         * 切换产品分类
         */
        changeProductClass(class_id){
            this.formData.class_id = class_id
            this.getBrandListHandler()
        },
        /**
         * 获取产品分类列表
         */
        async getProductClassListHandler(){
            const res = await getProductClassList()
            if(res){
                this.productClassList = res.list.map(r => {
                    r.label = r.class_name
                    r.value = r.class_id
                    return r
                })
                this.formData.class_id = this.productClassList.length == 0 ? undefined : this.productClassList[0].class_id
                this.getBrandListHandler()
            }
        },
         /**
         * 获取品牌列表
         */
        async getBrandListHandler(){
            if(this.formData.class_id == 'undefined') return
            const res = await getBrandList({class_id: this.formData.class_id})
            if(res){
                this.brandList = res.list.map(r => {
                    r.label = r.brand_name
                    r.value = r.brand_id
                    return r
                })
                this.formData.brand_id = this.brandList.length == 0 ? undefined :this.brandList[0].brand_id
            }
        },
         /**
         * 获取品牌列表
         */
        async getBrandAllListHandler(){
            const res = await getBrandList()
             this.allBrandList = res.list.map(r => {
                r.label = r.brand_name
                r.value = r.brand_id
                return r
            })
        },
        /**
         * 初始化参数
         */
        initParams(){
            this.formData = {
                product_id: 0,
                product_name: '',
                product_description: '',
                product_price: '',
                product_image: '',
                class_id: 'undefined',
                brand_id: 'undefined',
                datasheet: '',
                installation_manual: '',
                warranty_document: '',
                state: 0,
                action_type: 'add'
            }
        },
        /**
         * 下载模板
         */
        async getTemplateHandler(){
            const res = await getTemplate()
            this.downloadFile(res.url)
        },
        async customRequestExcel(file){
            this.spinning = true
            const data = await this.readerData(file)
            for(let i=0; i<data.length; i++){
                data[i].product_id = 0
                data[i].action_type = 'add'
                data[i].is_import = 0
                await updateProduct(data[i])
            }
            this.spinning = false
            message.success('success')
            this.getProductListHandler()
        },
        //读取文件
        readerData(rawFile) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = e => {
                    const data = e.target.result
                    const workbook = XLSX.read(data, { type: 'array' })
                    const firstSheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[firstSheetName]
                    const header = this.getHeaderRow(worksheet)
                    const results = XLSX.utils.sheet_to_json(worksheet)
                    //this.generateData({ header, results })
                    resolve(results)
                }
                reader.readAsArrayBuffer(rawFile)
            })
        },
        //获取表头
        getHeaderRow(sheet) {
            const headers = []
            const range = XLSX.utils.decode_range(sheet['!ref'])
            let C
            const R = range.s.r
            /* start in the first row */
            for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
                /* find the cell in the first row */
                let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
                headers.push(hdr)
            }
            return headers
        },
        //生成数据
        generateData({ header, results }) {
            const dataList = []
            const that = this
            results.forEach((item, index) => {
                let formData = {}
                for(let key in item){
                    const name = that.findKey(that.enum, key)
                    formData[name] = item[key]
                }
                if(formData.IdNumber && that.idNumber(formData.IdNumber) &&
                   formData.NameAct && that.nameAct(formData.NameAct) &&
                   formData.Sex && that.sex(formData.Sex) && that.getMobile(formData)
                ){
                    formData.reqPass = true
                }else{
                    formData.reqPass = false
                }
                if(that.getMobileFormat(formData.Mobile1) && that.getMobileFormat(formData.Mobile2) && 
                    that.getMobileFormat(formData.Mobile3) &&  that.getMobileFormat(formData.Mobile4))
                {
                    formData.mobilePass = true
                }else{
                    formData.mobilePass = false
                }
                dataList.push(formData)
            })
            const data = {
                header: header,
                results: dataList,
                pass: dataList.filter(r => !r.reqPass || !r.mobilePass).length > 0 ? false : true
            }
            this.$emit('onUploadExcel',data)
        },
        findKey(data, value, compare = (a, b) => a === b) {
            return Object.keys(data).find(k => compare(data[k], value))
        },
        changePagination(page){
            this.query.page = page
            this.getProductListHandler()
        }
    },
    mounted(){
        this.initParams()
        this.getProductListHandler()
        this.getBrandAllListHandler()
    }
}
</script>
<style lang="stylus" scoped>
.file_icon
    display block
    margin-bottom 10px
    font-size 30px
    color #43af52
    text-align left
.product_image
    display block
    margin-bottom 10px
    width 60px
</style>